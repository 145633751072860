
import {computed, defineComponent} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import {useI18n} from 'vue-i18n';
import {useStore} from 'effector-vue/composition.cjs';
import Logo from '@/components/Logo.vue';
import Test from '@/views/Test.vue';
import {$conferenceStore, connectEffect, DetailsState} from "@/store/room";

export default defineComponent({
    name: 'Start',
    components: {
      Logo,
      Test,
    },
    setup: () => {
      const { t } = useI18n();
      const route = useRoute();
      const router = useRouter();
      const state = useStore($conferenceStore)
      const { conference } = route.params;
      let conferenceId = ''
      if (typeof conference === 'string') {
        conferenceId = conference
      } else {
        conferenceId = conference[0]
      }
      connectEffect(conferenceId)

      $conferenceStore.watch((s) => {
        console.log('INIT STATE: ' + s.state);
        if (s.state !== DetailsState.NoInit) {
          (async () => {
            console.log('CONFERENCE ID:' + conferenceId);
            await router.replace({
              name: 'Join',
              params: {
                conference: conferenceId,
              },
            });
          })();
        }
      });

      const isConnected = computed(() => {
        return state.value.state !== DetailsState.NoInit
      });
      return {
        t,
        isConnected,
      };
    },
  });
